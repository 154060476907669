<template>
  <div>
    <v-container>
      <p>
        Identify the pair(s) of atoms and ions that are <b><i>isoelectronic.</i></b>
      </p></v-container
    >
    <v-row v-for="pair in shuffledPairs" :key="pair.input">
      <v-checkbox v-model="inputs[pair.input]">
        <template #label>
          <chemical-element-value :value="pair.atom" />&nbsp;and&nbsp;<chemical-latex
            :content="pair.ion"
          />
        </template>
      </v-checkbox>
    </v-row>
  </div>
</template>

<script>
import ChemicalElementValue from '@/tasks/components/ChemicalElementValue.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'IdentifyIsoelectronicPairs',
  components: {ChemicalLatex, ChemicalElementValue},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      answer: null,
      inputs: {
        pair1Selected: null,
        pair2Selected: null,
        pair3Selected: null,
        pair4Selected: null,
        pair5Selected: null,
      },
    };
  },
  computed: {
    shuffledPairs() {
      const seed = this.$gate.user?.id || 1;

      return seededShuffle(this.pairs, seed);
    },

    pairs() {
      const ions = [
        {Mg: 'Na^+', Ca: 'K^+', Sr: 'Rb^+'},
        {Ne: 'F^-', Ar: 'Cl^-', Kr: 'Br^-'},
        {C: 'O^2^-', Si: 'S^2^-', Ge: 'Se^2^-'},
        {Zn: 'Ni^2^+', Cu: 'Co^2^+', Cd: 'Ag^+'},
        {He: 'B^3^+', Ne: 'Al^3^+', Ar: 'Ca^2^+'},
      ];
      return ['pair1', 'pair2', 'pair3', 'pair4', 'pair5'].map((symbol, index) => {
        let atom = this.taskState.getValueBySymbol(symbol).content;
        return {
          atom: atom,
          ion: ions[index][atom.symbol],
          input: `${symbol}Selected`,
        };
      });
    },
  },
};
</script>

<style scoped></style>
